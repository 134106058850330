@font-face {
  font-family: Corbel;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/corbel/da27101d-d57c-4768-a7dd-f3894e18f937.eot?#iefix");
  src: url("/fonts/corbel/da27101d-d57c-4768-a7dd-f3894e18f937.eot?#iefix") format("eot"), url("/fonts/corbel/48c9986c-2b59-4ccf-b59c-f9d4b37fb6f6.woff2") format("woff2"), url("/fonts/corbel/1190296d-06a6-4c16-a8b4-7ac3061553db.woff") format("woff"), url("/fonts/corbel/e202a228-2b64-407d-a98f-88036f64a623.ttf") format("truetype"), url("/fonts/corbel/08c36e1b-2a12-4a86-b2c4-54abb92b2a38.svg#08c36e1b-2a12-4a86-b2c4-54abb92b2a38") format("svg"); }

@font-face {
  font-family: Corbel;
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/corbel/b1018bc9-fea0-485a-9998-b377a5e5a78d.eot?#iefix");
  src: url("/fonts/corbel/b1018bc9-fea0-485a-9998-b377a5e5a78d.eot?#iefix") format("eot"), url("/fonts/corbel/1b2e9b94-8740-4444-8a8c-6d2a86ac71b3.woff2") format("woff2"), url("/fonts/corbel/a4263124-5948-47f3-b0c8-a912e9494c04.woff") format("woff"), url("/fonts/corbel/95415cc3-5958-4ae2-a733-351cda74efc6.ttf") format("truetype"), url("/fonts/corbel/a0af0862-06ac-43ef-b405-3df107fa310f.svg#a0af0862-06ac-43ef-b405-3df107fa310f") format("svg"); }

@font-face {
  font-family: Corbel;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/corbel/e0ce9114-d5ac-4728-b041-b9b39b2c94ca.eot?#iefix");
  src: url("/fonts/corbel/e0ce9114-d5ac-4728-b041-b9b39b2c94ca.eot?#iefix") format("eot"), url("/fonts/corbel/717edc3f-a83b-4254-9158-4868b6db2276.woff2") format("woff2"), url("/fonts/corbel/037ee5b7-78b6-4496-9ebc-b951a7a5a53b.woff") format("woff"), url("/fonts/corbel/0aa03edc-71e1-4dcc-9830-abc1bb58729e.ttf") format("truetype"), url("/fonts/corbel/24f18aeb-bac3-4e8a-8aa8-15b73e5e2e45.svg#24f18aeb-bac3-4e8a-8aa8-15b73e5e2e45") format("svg"); }

@font-face {
  font-family: Corbel;
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/corbel/79f08807-4acd-4c73-bfd0-e3da9a8e68f9.eot?#iefix");
  src: url("/fonts/corbel/79f08807-4acd-4c73-bfd0-e3da9a8e68f9.eot?#iefix") format("eot"), url("/fonts/corbel/27f66fc9-7dbe-4c0c-ab28-0c2552eb40f1.woff2") format("woff2"), url("/fonts/corbel/9c1ca10d-6613-4533-8fe0-1a47ba7d5ec9.woff") format("woff"), url("/fonts/corbel/b244a6d4-bfc7-4ed2-a380-df1463c74536.ttf") format("truetype"), url("/fonts/corbel/453ee5e2-474f-4542-877e-4b4bbe826621.svg#453ee5e2-474f-4542-877e-4b4bbe826621") format("svg"); }
